import { Backdrop, CircularProgress } from "@mui/material";
import useAuth from "../../hooks/useAuth";

const Logout = () => {
  const { logout } = useAuth();

  logout();

  return (
    <Backdrop
      sx={{
        color: "primary",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Logout;
