interface Message {
  id: string;
  user_id: string;
  message: string;
  type: string;
  name: string;
  created_at: string;
  updated_at: string;
  active: string;
}

function groupMessagesByUserId(messages: Message[]): any[] {
  const groupedMessagesMap: { [userId: string]: any } = {};

  messages.forEach((message) => {
    const userId = message.user_id;
    if (!groupedMessagesMap[userId]) {
      groupedMessagesMap[userId] = {
        user_id: userId,
        messages: [],
        last_message_at: "",
        name: message.name,
      };
    }

    // Push the message to the user's message list
    groupedMessagesMap[userId].messages.push(message);

    // Update the last_message_at
    if (
      !groupedMessagesMap[userId].last_message_at ||
      message.created_at > groupedMessagesMap[userId].last_message_at
    ) {
      groupedMessagesMap[userId].last_message_at = message.created_at;
    }
  });

  const groupedMessages = Object.values(groupedMessagesMap);

  // Tri des messages par ordre de la date du dernier message
  groupedMessages.sort((a, b) => {
    const dateA = new Date(a.last_message_at);
    const dateB = new Date(b.last_message_at);
    return dateB.getTime() - dateA.getTime();
  });

  return groupedMessages;
}

export default groupMessagesByUserId;
