import { Box, Button, Chip } from "@mui/material";
import { useQueryLoan, useQueryStats } from "../../../api/queries";
import CardStat from "../../../components/layout/cardStat/cardStat";
import Datagrid from "../../../components/layout/customTable/Datagrid";
import Loading from "../../../components/layout/loading/Loading";
import SectionLayout from "../../../components/layout/sectionLayout";
import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { newSelectObjectByProperties } from "../../../utils/selectObjectByProperties";

const FarmOnLoan = () => {
  const { data: dataStats, isLoading } = useQueryStats();
  const { data: dataLoan, isLoading: isLoadingLoan } = useQueryLoan();

  const columns = [
    { label: "id", property: "id" },
    { label: "Farm Name", property: "name" },
    {
      label: "Loan Package",
      property: "type_loan",
      formatter: (text: string | number) => `Loan Package: ${text}`,
    },
    { label: "Amount", property: "amount", formatter: formatCurrency },
    { label: "Date Applied", property: "created_at", formatter: formatDate },
    { label: "Date Approved", property: "approved_at", formatter: formatDate },
    {
      label: "Interest Rate",
      property: "interest",
      formatter: (text: string | number) => (
        <Chip color="primary" label={`${text}%`} />
      ),
    },
  ];
  const users =
    dataLoan?.data.filter(
      (item: any) => item.paid === "0" && item.status !== "rejected"
    ) || [];
  const selectedRows = newSelectObjectByProperties(users, columns);

  return (
    <div>
      <Loading isLoading={isLoading || isLoadingLoan} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardStat
          title="Total Amount of Loan"
          amount={formatCurrency(dataStats?.data?.totalAmountOnLoan || 0)}
        />
        <CardStat
          title="Farm on Loan "
          amount={`${dataStats?.data?.farmOnLoan}`}
        />
      </Box>

      <SectionLayout title="List of Farms on Loan">
        <Datagrid columns={columns} rows={selectedRows} showButton />
      </SectionLayout>
    </div>
  );
};

export default FarmOnLoan;
