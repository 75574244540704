import React, { useEffect, useState } from "react";
import Loading from "../../components/layout/loading/Loading";
import { useQueryOrderDetail } from "../../api/queries";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Card from "../../components/card/card";
import KeyValueText from "../../components/keyValueText/keyValueText";
import { formatDate } from "../../utils/formatDate";
import SectionLayout from "../../components/layout/sectionLayout";
import Datagrid from "../../components/layout/customTable/Datagrid";
import formatCurrency from "../../utils/formatCurrency";
import { newSelectObjectByProperties } from "../../utils/selectObjectByProperties";
import Button from "../../components/button/customButton";
import { useMutationMarkAsComplete } from "../../api/mutations";

const OrderDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useQueryOrderDetail(id);
  const {
    mutate: markAsCompleted,
    isPending,
    isSuccess,
  } = useMutationMarkAsComplete();
  const order = data?.data || null;
  const [status, setStatus] = useState("");

  const columns = [
    { label: "id", property: "id" },

    {
      label: "Items",
      property: "name",
    },
    {
      label: "Quantity",
      property: "quantity",
    },
    {
      label: "Amount",
      property: "price",
      formatter: formatCurrency,
    },
  ];
  const record = order?.products || [];
  const selectedRows = newSelectObjectByProperties(record, columns);
  useEffect(() => {
    setStatus(order?.status);
    if (isSuccess) {
      setStatus("completed");
    }
  }, [order, isPending]);

  return (
    <>
      <Loading isLoading={isLoading || isPending} />
      {!isLoading && (
        <>
          <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
            <Link
              color="inherit"
              to="/"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Home
            </Link>
            <Link
              color="inherit"
              to="/Store"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Store
            </Link>
            <Typography color="#359444">Order No: AJI-00{order?.id}</Typography>
          </Breadcrumbs>
          <Card title="Farm Details">
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                padding: 2,
              }}
            >
              <Typography sx={{ px: 1, fontWeight: "600" }}>
                Order No: AJI-00{order?.id}
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Signee Name"
                  value={order?.name}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Order Date"
                  value={formatDate(order?.created_at)}
                  isVertical
                />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Email Address"
                  value={order?.email}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Phone Number"
                  value={order?.phonenumber}
                  isVertical
                />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Billing Address"
                  value={`${order?.region} ${order?.state} ${order?.country}`}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText title="City" value={order?.state} isVertical />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Country"
                  value={order?.country}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}></Box>
            </Box>
          </Card>
          <Box sx={{ paddingX: 4 }}>
            <SectionLayout title="Farms Log">
              <Datagrid columns={columns} rows={selectedRows} />
            </SectionLayout>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 4,
              paddingBottom: 15,
            }}
          >
            <Box sx={{ width: "40%" }}>
              <FormControlLabel
                control={<Checkbox />}
                label="Order has been completed"
              />
            </Box>
            <Box sx={{ width: "45%" }}>
              <Typography variant="h6">Total</Typography>

              <Box sx={{ paddingY: 2 }}>
                <KeyValueText title="Amount" value={order?.total_amount} />
              </Box>

              <Divider />
              <Box sx={{ paddingY: 2 }}>
                <KeyValueText title="Shipping" value={formatCurrency(0)} />
              </Box>
              <Divider />
              <Box sx={{ paddingY: 2 }}>
                <KeyValueText
                  title="Total"
                  value={formatCurrency(order?.total_amount)}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  size={"large"}
                  disabled={isPending}
                  sx={{ mt: 3, mb: 2, mx: 2 }}
                  onClick={() => null}
                >
                  Download Details
                </Button>
                {status !== "completed" && (
                  <Button
                    type="submit"
                    disabled={isPending}
                    isLoading={isPending}
                    fullWidth
                    variant="contained"
                    size={"large"}
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() =>
                      markAsCompleted({ orderId: String(order?.id) })
                    }
                  >
                    Mark Order has Completed
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default OrderDetails;
