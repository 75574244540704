import { Box } from "@mui/material";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/layout/header/header";
import SideNav from "../components/layout/sideNav/sideNav";
import Logout from "../pages/auth/logout";
import FarmTrackPage from "../pages/farmTracks/farmTrackPage";
import HomePage from "../pages/home/homePage";
import LoanPage from "../pages/loan/loanPage";
import NotificationPage from "../pages/notification/notificationPage";
import SettingPage from "../pages/settings/settingPage";
import StorePage from "../pages/store/storePage";
import TransactionPage from "../pages/transactions/transactionsPage";
import ChatPage from "../pages/chat/chatPage";
import FarmDetailsPage from "../pages/farmTracks/farmDetailsPage";
import OrderDetailsPage from "../pages/store/orderDetails";
import LoanDetailsPage from "../pages/loan/LoanDetailsPage";

function AppRouter() {
  const [open, setOpen] = useState(true);

  const handleDrawerOpenClose = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        flex: 1,
        width: "100%",
      }}
    >
      <SideNav open={open} handleDrawerOpenClose={handleDrawerOpenClose} />
      <Box sx={{ width: "100%" }}>
        <Header
          handleDrawerOpenClose={handleDrawerOpenClose}
          isHomePage={false}
        />
        <Routes>
          <Route path="/">
            <Route path="/" element={<HomePage />} />
          </Route>
          <Route path="/notifications" element={<NotificationPage />} />
          <Route path="/transactions" element={<TransactionPage />} />
          <Route path="/loans" element={<LoanPage />} />
          <Route path="/loans/:id" element={<LoanDetailsPage />} />
          <Route path="/farmtracks" element={<FarmTrackPage />} />
          <Route path="/farmtracks/:id" element={<FarmDetailsPage />} />
          <Route path="/store/:id" element={<OrderDetailsPage />} />
          <Route path="/chats" element={<ChatPage />} />
          <Route path="/store" element={<StorePage />} />
          <Route path="/settings" element={<SettingPage />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default AppRouter;
