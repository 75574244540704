import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";

const useQueryOrderDetail = (id?: string | number) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ["OrderDetails", id],
    queryFn: () => fetchApi(`orderDetails?id=${id}`, token).get(),
    refetchOnWindowFocus: false,
  });
};

export default useQueryOrderDetail;
