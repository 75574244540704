import { Box, Tab, Tabs } from "@mui/material";
import * as React from "react";
import CustomTabPanel from "../../components/layout/customTabPanel/customTabPanel";
import LoanPaymentList from "./components/loanPaymentList";
import TransactionList from "./components/transactionList";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TransactionPage() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pt: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{
              textTransform: "none",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "23.5px",
            }}
            label="Transactions"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              textTransform: "none",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "23.5px",
            }}
            label="Loan Payment"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <LoanPaymentList />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TransactionList />
      </CustomTabPanel>
    </Box>
  );
}
