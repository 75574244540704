import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useMutation } from "@tanstack/react-query";

const useMutationAddMessage = (options?: any) => {
  const { token } = useAuth();
  return useMutation({
    mutationKey: ["AddMessage"],
    mutationFn: ({ message, userid }: { message: string; userid: string }) =>
      fetchApi("chat", token).post({
        message: message.trim(),
        user_id: userid,
      }),
  });
};

export default useMutationAddMessage;
