import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";

const useQueryLoan = (options?: any) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ["LoanRequest"],
    queryFn: () => fetchApi("loanRequest", token).get(),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
  });
};

export default useQueryLoan;
