import { Box } from "@mui/material";
import { useQueryStats, useQueryUser } from "../../../api/queries";
import CardStat from "../../../components/layout/cardStat/cardStat";
import Datagrid from "../../../components/layout/customTable/Datagrid";
import Loading from "../../../components/layout/loading/Loading";
import SectionLayout from "../../../components/layout/sectionLayout";
import { formatDate } from "../../../utils/formatDate";
import { newSelectObjectByProperties } from "../../../utils/selectObjectByProperties";
import { RecentFarmChart } from "./recentFarmChart";

const RecentFarmAdded = () => {
  const { data } = useQueryUser();
  const { isLoading, data: dataStats } = useQueryStats();

  const columns = [
    { label: "id", property: "id" },
    { label: "Farm Name", property: "name" },
    {
      label: "Farm Type",
      property: "small_farm",
      formatter: (text: string | number) =>
        text === "1" ? "Small Holder Farms" : "Individual Holder farms",
    },

    { label: "Date Joined", property: "created_at", formatter: formatDate },
    {
      label: "Number of Livestock",
      property: "livestock",
      formatter: (text: string | number) => text + " Chiks",
    },
  ];
  const users = data?.data.filter((item: any) => item.small_farm === "1") || [];
  const selectedRows = newSelectObjectByProperties(users, columns);

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardStat
          title="Number of Farms"
          amount={`${dataStats?.data?.farmNumber || "-"}`}
        />
        <CardStat
          title="Number of Farms on Loan"
          amount={`${dataStats?.data?.farmOnLoan}`}
        />
        <CardStat
          title="Number of Farms without Loan"
          amount={`${dataStats?.data?.farmWithoutLoan}`}
        />
      </Box>
      <SectionLayout title="Farms( amount in tens)">
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Box sx={{ height: "40vh", width: "100%", alignSelf: "center" }}>
            <RecentFarmChart />
          </Box>
        </Box>
      </SectionLayout>

      <SectionLayout title="Recents Farms Added">
        <Datagrid columns={columns} rows={selectedRows} showButton />
      </SectionLayout>
    </div>
  );
};

export default RecentFarmAdded;
