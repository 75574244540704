import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useQueryStats } from "../../api/queries";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Income",
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Oct",
  "Nov",
  "Dec",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Income",
      data: labels.map(() => Math.random() * 100),
      backgroundColor: "#FFCE00",
      borderWith: 150,
      width: "12px",
    },
  ],
};

export function DashboardChart() {
  const { data } = useQueryStats();

  const incomeStats = data?.data?.monthlyIncome || [];

  const dataBar = {
    labels,
    datasets: [
      {
        label: "Amount ",

        data: labels.map(
          (_, index) =>
            incomeStats?.find((item: any) => item?.month === `${index + 1}`)
              ?.total_amount || 0
        ),
        backgroundColor: "#FFCE00",
        borderWith: 150,
        width: "12px",
      },
    ],
  };
  return <Bar options={options} data={dataBar} />;
}
