import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";

const useQueryStats = (options?: any) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ["Stats"],
    queryFn: () => fetchApi("stats", token).get(),
    staleTime: 10000 * 60,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
  });
};

export default useQueryStats;
