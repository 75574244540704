import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import fetchApi from "../../api/fetchApi";
import Button from "../../components/button/customButton";
import Footer from "../../components/footer/footer";
import CustomInput from "../../components/inputs/cutsomInput";
import useAuth from "../../hooks/useAuth";
import ReactCodeInput from "react-verification-code-input";

const ChangePassword = () => {
  const { handleSubmit, control } = useForm();
  const [isLogin, setIsLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1);
  const [seconds, setSeconds] = useState(60);
  const [resend, setResend] = useState(false);

  const resetPassword = async (data: any) => {
    setIsLogin(true);
    setErrorMessage("");
    fetchApi("reset_email")
      .post({
        email: data.email.trim(),
      })
      .then((t) => {
        if (t.ok) {
          setStep(2);
        } else {
          setErrorMessage(t.error);
        }
      })
      .catch((err) => {
        setErrorMessage("An error Occured. kindly try later!");
      })
      .finally(() => setIsLogin(false));
  };

  useEffect(() => {
    if (step === 2) {
      const timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          setResend(true);
          clearInterval(timer);
        }
      }, 1000);
      return () => clearInterval(timer);
    }
    // Nettoyer le timer à la fin du composant
  }, [seconds, step]);

  const formattedTime = `${Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="/assets/logo.png" alt="Logo" style={{ width: "auto" }} />
        <Box sx={{ width: "100%" }}>
          <Typography
            component="h3"
            variant="h5"
            textAlign={"left"}
            pt={5}
            color={"red"}
          >
            {errorMessage}
          </Typography>
          <Typography component="h1" variant="h5" textAlign={"left"} pt={5}>
            {step === 1 && "Change Password"}
            {step === 2 && "Verification code"}
            {step === 3 && "New Password"}
          </Typography>
          <Typography component="h6" variant="h6" textAlign={"left"} pt={5}>
            {step === 1 && "Enter your email to change your password"}
            {step === 2 && "Enter a verification code"}
            {step === 3 && "Enter a new password"}
          </Typography>
        </Box>

        <Box sx={{ mt: 1 }}>
          {step === 1 && (
            <>
              <CustomInput
                control={control}
                id="email"
                label="Email Address"
                name="email"
                disabled={isLogin}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "invalid email",
                  },
                }}
                autoFocus
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                size={"large"}
                sx={{ mt: 3, mb: 2 }}
                isLoading={isLogin}
                disabled={isLogin}
                onClick={handleSubmit(resetPassword)}
              >
                Continue
              </Button>
            </>
          )}

          {step === 2 && (
            <>
              <ReactCodeInput />
              <Typography sx={{ color: "red", pt: 5 }}>
                Verification code will expire in ({formattedTime})
              </Typography>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                size={"large"}
                sx={{ mt: 3, mb: 2 }}
                isLoading={isLogin}
                disabled={isLogin}
                onClick={handleSubmit(resetPassword)}
              >
                Continue
              </Button>
            </>
          )}

          {step === 3 && (
            <>
              <CustomInput
                control={control}
                name="password"
                label="Password"
                type="password"
                id="password"
                disabled={isLogin}
                rules={{
                  required: "This field is required",
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                size={"large"}
                sx={{ mt: 3, mb: 2 }}
                isLoading={isLogin}
                disabled={isLogin}
                onClick={handleSubmit(resetPassword)}
              >
                Continue
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ position: "absolute", bottom: 25, left: 0, right: 0 }}>
        <Divider />
        <Footer sx={{ mt: 8, mb: 4 }} />
      </Box>
    </Container>
  );
};

export default ChangePassword;
