import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useMutation } from "@tanstack/react-query";

const useMutationAcceptLoan = (options?: any) => {
  const { token } = useAuth();
  return useMutation({
    mutationKey: ["AcceptLoan"],
    mutationFn: ({ loanId }: { loanId: string }) =>
      fetchApi(`acceptLoan?id=${loanId}`, token).get(),
  });
};

export default useMutationAcceptLoan;
