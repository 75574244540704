import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useMutation } from "@tanstack/react-query";

const useMutationRejectLoan = (options?: any) => {
  const { token } = useAuth();
  return useMutation({
    mutationKey: ["RejectLoan"],
    mutationFn: ({ loanId }: { loanId: string }) =>
      fetchApi(`rejectLoan?id=${loanId}`, token).get(),
  });
};

export default useMutationRejectLoan;
