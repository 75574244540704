import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useMutation } from "@tanstack/react-query";

const useMutationMarkAsComplete = (options?: any) => {
  const { token } = useAuth();
  return useMutation({
    mutationKey: ["MarkAsCompleted"],
    mutationFn: ({ orderId }: { orderId: string }) =>
      fetchApi("markAsCompleted", token).post({
        order_id: orderId,
      }),
  });
};

export default useMutationMarkAsComplete;
