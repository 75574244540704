import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useQueryStats } from "../../../api/queries";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Number of farms register against month of the year",
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Oct",
  "Nov",
  "Dec",
];

export function RecentFarmChart() {
  const { data: dataStats } = useQueryStats();

  const userMonthlyRegistrationStats =
    dataStats?.data?.userMonthlyRegistration || [];

  const data = {
    labels,
    datasets: [
      {
        label: "Number of farms register against month of the year",
        data: labels.map(
          (_, index) =>
            userMonthlyRegistrationStats?.find(
              (item: any) => item?.month === `${index + 2}`
            )?.total_users || 0
        ),
        backgroundColor: "#FFCE00",
        borderWith: 150,
        width: "12px",
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
