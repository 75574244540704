import { Box } from "@mui/material";
import { useQueryUser } from "../../../api/queries";
import CardStat from "../../../components/layout/cardStat/cardStat";
import CustomTable from "../../../components/layout/customTable/customTable";
import SectionLayout from "../../../components/layout/sectionLayout";
import selectObjectByProperties, {
  newSelectObjectByProperties,
} from "../../../utils/selectObjectByProperties";
import { formatDate } from "../../../utils/formatDate";
import Datagrid from "../../../components/layout/customTable/Datagrid";

const IndividualFarm = () => {
  const { data } = useQueryUser();
  const columns = [
    { label: "id", property: "id" },
    { label: "Farm Name", property: "name" },
    {
      label: "Farm Type",
      property: "small_farm",
      formatter: (text: string | number) =>
        text === "1" ? "Small Holder Farms" : "Individual Holder farms",
    },

    { label: "Date Joined", property: "created_at", formatter: formatDate },
    {
      label: "Number of Livestock",
      property: "livestock",
      formatter: (text: string | number) => text + " Chiks",
    },
  ];
  const transactions = data?.data || [];
  const users =
    transactions?.filter((item: any) => item?.small_farm === "0") || [];

  const selectedRows = newSelectObjectByProperties(users, columns);

  return (
    <div>
      <SectionLayout title="Recents Farms Added">
        <Datagrid columns={columns} rows={selectedRows} showButton />
      </SectionLayout>
    </div>
  );
};

export default IndividualFarm;
