import { Chip } from "@mui/material";
import { useQueryLoan } from "../../../api/queries";
import Datagrid from "../../../components/layout/customTable/Datagrid";
import Loading from "../../../components/layout/loading/Loading";
import SectionLayout from "../../../components/layout/sectionLayout";
import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { newSelectObjectByProperties } from "../../../utils/selectObjectByProperties";

const LoanApplicationList = () => {
  const { data: dataLoan, isLoading: isLoadingLoan } = useQueryLoan();

  const columns = [
    { label: "id", property: "id" },
    { label: "Farm Name", property: "name" },
    {
      label: "Loan Package",
      property: "type_loan",
      formatter: (text: string | number) => `Loan Package: ${text}`,
    },
    { label: "Amount", property: "amount", formatter: formatCurrency },
    { label: "Date Applied", property: "created_at", formatter: formatDate },
    {
      label: "Status",
      property: "status",
      formatter: (text: string | number) => (
        <Chip
          color={
            text === "approved" ? "primary" : text === "open" ? "info" : "error"
          }
          label={`${text}`}
        />
      ),
    },
    {
      label: "Interest Rate",
      property: "interest",
      formatter: (text: string | number) => (
        <Chip color="primary" label={`${text}%`} />
      ),
    },
  ];
  const users = dataLoan?.data.filter((item: any) => item.paid === "0") || [];
  const selectedRows = newSelectObjectByProperties(users, columns);

  return (
    <div>
      <Loading isLoading={isLoadingLoan} />

      <SectionLayout title="List of Farms on Loan">
        <Datagrid columns={columns} rows={selectedRows} showButton />
      </SectionLayout>
    </div>
  );
};

export default LoanApplicationList;
