import { Chip } from "@mui/material";
import { useQueryTransaction } from "../../../api/queries";
import Datagrid from "../../../components/layout/customTable/Datagrid";
import SectionLayout from "../../../components/layout/sectionLayout";
import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { newSelectObjectByProperties } from "../../../utils/selectObjectByProperties";

const TransactionList = () => {
  const { data } = useQueryTransaction();
  const columns = [
    { label: "Farm Name", property: "name" },
    { label: "Phone", property: "phonenumber" },

    { label: "Date", property: "created_at", formatter: formatDate },
    {
      label: "Status",
      property: "status",
      formatter: (text: string | number) => (
        <Chip color={"primary"} label={`Paid`} />
      ),
    },
    { label: "Amount", property: "amount", formatter: formatCurrency }, // Assurez-vous que la propriété "action" existe dans vos objets row
  ];
  const transactions =
    data?.data.filter((item: any) => item.type === "loan") || [];
  const selectedRows = newSelectObjectByProperties(transactions, columns);
  return (
    <div>
      <SectionLayout title="Recents Transactions">
        <Datagrid columns={columns} rows={selectedRows} />
      </SectionLayout>
    </div>
  );
};

export default TransactionList;
