import apihost from "./apihost";

export default function fetchApi(endpoint?: string, token?: null | string) {
  const apiKey = "VOTRE_CLE_API"; // Remplacez par votre clé API

  const headers = {
    Authorization: `Bearer ${token}`,
    "X-API-KEY": apiKey,
    Accept: "application/json",
    "Content-Type": "application/json",
    Origin: "*",
    "X-Requested-With": "XMLHttpRequest",
  };

  return {
    get: async (params?: any) => {
      const data = await fetch(`${apihost}/${endpoint}${params ?? ""}`, {
        method: "GET",
        headers,
      });

      return data.json();
    },
    post: async (params?: any) => {
      const data = await fetch(`${apihost}/${endpoint}`, {
        method: "POST",
        headers,
        body: JSON.stringify(params),
      });

      return data.json();
    },
    login: async (params?: any) => {
      const data = await fetch(`${apihost}/login`, {
        method: "POST",
        body: JSON.stringify(params),
      });

      return data.json();
    },
    register: async (params?: any) => {
      const data = await fetch(
        `${apihost}/register?email=${params.email}&password=${params.password}&confirm_password=${params.confirm_password}`,
        {
          method: "POST",
          body: JSON.stringify(params),
        }
      );

      return data.json();
    },
  };
}
