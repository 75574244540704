import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";

const useQueryTransaction = (options?: any) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ["Transactions"],
    queryFn: () => fetchApi("transactions", token).get(),
    staleTime: 10000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
};

export default useQueryTransaction;
