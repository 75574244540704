import React, { ReactNode, useEffect, useState } from "react";
import fetchApi from "../api/fetchApi";
import { User } from "../models/userModel";
import { AuthContext } from "../context/authContext";
import {
  LOGIN_STORAGE_KEY,
  LOGIN_STORAGE_KEY_USER,
} from "../constants/storageKeys";
import { useNavigate } from "react-router-dom";
import Loading from "../components/layout/loading/Loading";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [onLauched, setOnLauched] = useState<boolean>(false);
  const [profilePicture, setProfilePicture] = useState<string | undefined>();
  const [token, setToken] = useState<string | null>(null);

  const refreshUser = () => {
    /*fetchApi(`login/me`)
          .get(`?id=${user?.id}`)
          .then((e) => {
            setUser(e.data);
            console.log("refresh", e.data);
          });*/
  };

  /* eslint-disable */
  const updateUser = (data: any) => {
    const value = { ...user, ...data };
    setUser(value);
  };

  const login = async (e: any) => {
    const userObject = JSON.stringify(e.data);
    setIsLoading(true);
    try {
      sessionStorage.setItem(LOGIN_STORAGE_KEY_USER, userObject);
      sessionStorage.setItem(LOGIN_STORAGE_KEY, e.token);
      setToken(e.token);
      setUser(e.data);
    } catch (e) {}
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  };

  const logout = () => {
    setIsLoading(true);

    try {
      sessionStorage.removeItem(LOGIN_STORAGE_KEY_USER);
      sessionStorage.removeItem(LOGIN_STORAGE_KEY);
      setUser(null);
      navigate("/");
    } catch (e) {
      console.log(e);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  };

  useEffect(() => {
    const loadLocalUser = async () => {
      setIsLoading(true);
      const storagedUser = sessionStorage.getItem(LOGIN_STORAGE_KEY);
      if (storagedUser) {
        setToken(storagedUser);
        //const userDataLocal = JSON.parse(storagedUser);
        fetchApi(`login`, storagedUser)
          .get("/me")
          .then((e) => {
            if (e.data.at(0)) setUser(e.data.at(0));
            else {
              logout();
            }
          })
          .catch((e) => logout())
          .finally(() => setIsLoading(false));
      } else {
        setIsLoading(false);
      }
    };

    loadLocalUser();
  }, []);

  const value = React.useMemo(
    () => ({
      user,
      isLoading,
      onLauched,
      login,
      logout,
      refreshUser,
      updateUser,
      token,
    }),
    [user, isLoading, onLauched]
  );

  return (
    <AuthContext.Provider value={value}>
      {isLoading ? <Loading isLoading /> : children}
    </AuthContext.Provider>
  );
};
