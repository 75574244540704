import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";

const useQueryChat = (options?: any) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ["Chats"],
    queryFn: () => fetchApi("chat", token).get(),
    staleTime: 10000 * 60 * 10,
    refetchOnWindowFocus: false,
    refetchInterval: 10000 * 3,
    refetchIntervalInBackground: true,
  });
};

export default useQueryChat;
