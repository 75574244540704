import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import fetchApi from "../../api/fetchApi";
import Button from "../../components/button/customButton";
import Footer from "../../components/footer/footer";
import CustomInput from "../../components/inputs/cutsomInput";
import useAuth from "../../hooks/useAuth";

const LoginPage = () => {
  const { handleSubmit, control } = useForm();
  const [isLogin, setIsLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { login } = useAuth();

  const signIn = async (data: any) => {
    setIsLogin(true);
    setErrorMessage("");
    fetchApi("login_email")
      .post({
        email: data.email.trim(),
        password: data.password.trim(),
        role: "admin",
      })
      .then((t) => {
        if (t.token) {
          console.log(t);
          login(t);
        } else {
          setErrorMessage(t.error);
        }
      })
      .catch((err) => {
        setErrorMessage("An error Occured. kindly try later!");
      })
      .finally(() => setIsLogin(false));
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="/assets/logo.png" alt="Logo" style={{ width: "auto" }} />
        <Box sx={{ width: "100%" }}>
          <Typography
            component="h3"
            variant="h5"
            textAlign={"left"}
            pt={5}
            color={"red"}
          >
            {errorMessage}
          </Typography>
          <Typography component="h1" variant="h5" textAlign={"left"} pt={5}>
            Log in to Admin
          </Typography>
        </Box>

        <Box sx={{ mt: 1 }}>
          <CustomInput
            control={control}
            id="email"
            label="Email Address"
            name="email"
            disabled={isLogin}
            rules={{
              required: "This field is required",
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: "invalid email",
              },
            }}
            autoFocus
          />
          <CustomInput
            control={control}
            name="password"
            label="Password"
            type="password"
            id="password"
            disabled={isLogin}
            rules={{
              required: "This field is required",
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            size={"large"}
            sx={{ mt: 3, mb: 2 }}
            isLoading={isLogin}
            disabled={isLogin}
            onClick={handleSubmit(signIn)}
          >
            Sign In
          </Button>
        </Box>
      </Box>
      <Box sx={{ position: "absolute", bottom: 25, left: 0, right: 0 }}>
        <Divider />
        <Footer sx={{ mt: 8, mb: 4 }} />
      </Box>
    </Container>
  );
};

export default LoginPage;
