import React from "react";

import "./App.css";
import Navigation from "./navigation";
import { ThemeProvider } from "@mui/material";
import theme from "./config/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Navigation />
    </ThemeProvider>
  );
}

export default App;
