import { Box, Typography } from "@mui/material";
import React from "react";

const CardStat = ({ title, amount }: { title: string; amount: string }) => {
  return (
    <Box
      sx={{
        minHeight: "110px",
        minWidth: "33.3%",
        border: 0.5,
        borderColor: "#CFCFCF",
        padding: 5,
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "#0A0A0A",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "26.04px",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "48px",
          lineHeight: "62.5px",
          color: "#359444",
        }}
      >
        {amount}
      </Typography>
    </Box>
  );
};

export default CardStat;
