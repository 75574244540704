import { Box, Chip } from "@mui/material";
import { useQueryProduct } from "../../../api/queries";
import CardStat from "../../../components/layout/cardStat/cardStat";
import CustomTable from "../../../components/layout/customTable/customTable";
import SectionLayout from "../../../components/layout/sectionLayout";
import selectObjectByProperties, {
  newSelectObjectByProperties,
} from "../../../utils/selectObjectByProperties";
import { RecentFarmChart } from "./recentFarmChart";
import { sumProperty } from "../../../utils/sumProperty";
import { formatDate } from "../../../utils/formatDate";
import Datagrid from "../../../components/layout/customTable/Datagrid";

const Inventory = () => {
  const { data } = useQueryProduct();

  const columns = [
    { label: "id", property: "id" },
    { label: "Item", property: "name" },
    {
      label: "Quantity in Stock",
      property: "quantity",
    },

    { label: "Date", property: "created_at", formatter: formatDate },

    {
      label: "Status",
      property: "interest",
      formatter: (text: string | number) => (
        <Chip color="primary" label={`In Stock`} />
      ),
    },
  ];
  const transactions = data?.data || [];
  const selectedRows = newSelectObjectByProperties(transactions, columns);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardStat
          title="Number of Items in Inventory"
          amount={`${sumProperty(transactions, "quantity")}`}
        />
        <CardStat title="Number of Sold Items" amount={"0"} />
        <CardStat title="Number of Items on Loan" amount={"0"} />
      </Box>
      <SectionLayout title="Farms( amount in tens)">
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Box sx={{ height: "40vh", width: "100%", alignSelf: "center" }}>
            <RecentFarmChart />
          </Box>
        </Box>
      </SectionLayout>

      <SectionLayout title="List of Items Added">
        <Datagrid columns={columns} rows={selectedRows} showButton />
      </SectionLayout>
    </div>
  );
};

export default Inventory;
