import {
  Avatar,
  Box,
  Breadcrumbs,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import Card from "../../components/card/card";
import { useQueryFarmDetail } from "../../api/queries";
import Loading from "../../components/layout/loading/Loading";
import KeyValueText from "../../components/keyValueText/keyValueText";
import SectionLayout from "../../components/layout/sectionLayout";
import { useParams, Link } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import Datagrid from "../../components/layout/customTable/Datagrid";
import { newSelectObjectByProperties } from "../../utils/selectObjectByProperties";

const FarmDetailsPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useQueryFarmDetail(id);

  const farm = data?.data || null;

  const columns = [
    { label: "id", property: "id" },
    { label: "Day", property: "created_at", formatter: formatDate },
    {
      label: "Feed Consump.",
      property: "feed",
      formatter: (text: string | number) => `${text} Bags`,
    },
    {
      label: "Water Levels",
      property: "water",
      formatter: (text: string | number) => `${text} Litres`,
    },
    {
      label: "Temperature",
      property: "temperature",
      formatter: (text: string | number) => `${text} °C`,
    },

    {
      label: "Humidity Level",
      property: "humidity",
      formatter: (text: string | number) => text + " %",
    },
    {
      label: "Number of Death",
      property: "number_of_death_birds",
    },
    {
      label: "Number of Sick",
      property: "number_of_sick_birds",
    },
  ];
  const record = farm?.record || [];
  const selectedRows = newSelectObjectByProperties(record, columns);

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <>
          <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
            <Link
              color="inherit"
              to="/"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Home
            </Link>
            <Link
              color="inherit"
              to="/farmtracks"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              FarmTrack
            </Link>
            <Typography color="#359444">{farm?.name}'s Farm</Typography>
          </Breadcrumbs>

          <Divider />
          <Card title="Farm Details">
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                padding: 2,
              }}
            >
              <IconButton sx={{ p: 0 }}>
                <Avatar alt={farm?.name} src="./assets/ajiIcon.png" />
              </IconButton>

              <Typography sx={{ px: 3 }}>{farm?.name}</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="First Name"
                  value={farm?.name}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText title="Last Name" value="-" isVertical />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Email Address"
                  value={farm?.email}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Phone Number"
                  value={farm?.phone}
                  isVertical
                />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Address"
                  value={`${farm?.region} ${farm?.state} ${farm?.country}`}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText title="Role" value="Owner" isVertical />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Farm Type"
                  value={
                    farm?.small_farm === "1"
                      ? "Small Holder Farms"
                      : "Individual Holder Farms"
                  }
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Date Joined"
                  value={formatDate(farm?.created_at)}
                  isVertical
                />
              </Box>
            </Box>
          </Card>
          <Box sx={{ paddingX: 4 }}>
            <SectionLayout title="Farms Log">
              <Datagrid columns={columns} rows={selectedRows} />
            </SectionLayout>
          </Box>
        </>
      )}
    </>
  );
};

export default FarmDetailsPage;
