import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";

const useQueryOrder = (options?: any) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ["Order"],
    queryFn: () => fetchApi("orders", token).get(),
    staleTime: 10000,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
  });
};

export default useQueryOrder;
