import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";

const useQueryProduct = (options?: any) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ["Products"],
    queryFn: () => fetchApi("products", token).get(),
    staleTime: 10000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
};

export default useQueryProduct;
