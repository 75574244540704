import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useQueryStats } from "../../../api/queries";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Income",
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "sept",
  "Oct",
  "Nov",
  "Dec",
];

export function RecentFarmChart() {
  const { data } = useQueryStats();

  const orderStats = data?.data?.orderStats || [];

  const dataBar = {
    labels,
    datasets: [
      {
        label: "No Order",
        data: labels.map(
          (_, index) =>
            orderStats?.find((item: any) => item?.month === `${index + 1}`)
              ?.total_transactions || 0
        ),
        backgroundColor: "#FFCE00",
        borderWith: 150,
        width: "12px",
      },
    ],
  };

  return <Bar options={options} data={dataBar} />;
}
