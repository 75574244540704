import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ComponentType, useEffect } from "react";
import { NavLink } from "react-router-dom";

type MenuItemProps = {
  name: string;
  href: string;
  /* eslint-disable no-unused-vars */
  icon: ComponentType;
  children?: MenuItemProps[];
  extra?: ComponentType;
};

interface MenuProps {
  menu: MenuItemProps;
  isActive?: boolean;
}

function MenuItem({ menu, isActive = false }: MenuProps) {
  const { name, href, icon: ItemIcon } = menu;

  return (
    <NavLink
      key={name}
      to={href}
      end
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
    >
      <ListItem
        disablePadding
        sx={{ paddingY: 1, backgroundColor: isActive ? "#E5F4EE" : "inherit" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <ItemIcon />
          </ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>
    </NavLink>
  );
}

export default MenuItem;
