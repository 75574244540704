import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import useQueryLoanDetail from "../../api/queries/useQueryLoanDetails";
import Card from "../../components/card/card";
import KeyValueText from "../../components/keyValueText/keyValueText";
import Datagrid from "../../components/layout/customTable/Datagrid";
import Loading from "../../components/layout/loading/Loading";
import SectionLayout from "../../components/layout/sectionLayout";
import { formatDate } from "../../utils/formatDate";
import { newSelectObjectByProperties } from "../../utils/selectObjectByProperties";
import formatCurrency from "../../utils/formatCurrency";
import {
  useMutationAcceptLoan,
  useMutationRejectLoan,
} from "../../api/mutations";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

const LoanDetailsPage = () => {
  const { id } = useParams();

  const { mutate: rejectLoan, isPending, isSuccess } = useMutationRejectLoan();
  const {
    mutate: acceptLoan,
    isPending: isPendingAccept,
    isSuccess: isSuccessAccept,
  } = useMutationAcceptLoan();
  const { data, isLoading } = useQueryLoanDetail(id);

  const farm = data?.data || null;

  const columns = [
    { label: "id", property: "id" },
    { label: "Item Image", property: "created_at", formatter: formatDate },
    {
      label: "Items",
      property: "feed",
      formatter: (text: string | number) => `${text} Bags`,
    },
    {
      label: "Quantity",
      property: "water",
      formatter: (text: string | number) => `${text} Litres`,
    },
    {
      label: "Amount",
      property: "amount",
      formatter: (text: string | number) => `${text} °C`,
    },
  ];
  const record = farm?.record || [];
  const selectedRows = newSelectObjectByProperties(record, columns);
  const interest = (farm?.interest * farm?.amount) / 100 || 0;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess || isSuccessAccept)
      queryClient.invalidateQueries({ queryKey: ["LoanDetails"] });
    queryClient.invalidateQueries({ queryKey: ["LoanRequest"] });
    queryClient.invalidateQueries({ queryKey: ["Stats"] });
  }, [isSuccess, isSuccessAccept]);

  queryClient.invalidateQueries({ queryKey: ["todos"] });

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <>
          <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
            <Link
              color="inherit"
              to="/"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Home
            </Link>
            <Link
              color="inherit"
              to="/loans"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Loans
            </Link>
            <Typography color="#359444">{farm?.name}'s Farm</Typography>
          </Breadcrumbs>

          <Divider />
          <Card title="Loan Details">
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                padding: 2,
              }}
            >
              <IconButton sx={{ p: 0 }}>
                <Avatar alt={farm?.name} src="./assets/ajiIcon.png" />
              </IconButton>

              <Typography sx={{ px: 3 }}>{farm?.name}</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Address"
                  value={`${farm?.region} ${farm?.state} ${farm?.country}`}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Phone Number"
                  value={farm?.phonenumber}
                  isVertical
                />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Loan Package"
                  value={
                    farm?.small_farm === "1"
                      ? "Small Holder Farms"
                      : "Individual Holder Farms"
                  }
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Date Applied"
                  value={formatDate(farm?.created_at)}
                  isVertical
                />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Due Date"
                  value={`${farm?.region} ${farm?.state} ${farm?.country}`}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Profit Share"
                  value={<Chip label={`${farm?.interest} %`} color="success" />}
                  isVertical
                />
              </Box>
            </Box>
          </Card>
          <Box sx={{ paddingX: 4 }}>
            <SectionLayout title="Loan Details">
              <Datagrid columns={columns} rows={selectedRows} />
            </SectionLayout>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 4,
              paddingBottom: 15,
            }}
          >
            <Box sx={{ width: "40%" }}>
              {farm?.status === "open" && (
                <FormControlLabel
                  control={<Checkbox />}
                  label="All documents have been approved and verified"
                />
              )}
            </Box>
            <Box sx={{ width: "45%" }}>
              <Typography variant="h6">Total</Typography>

              <Box sx={{ paddingY: 2 }}>
                <KeyValueText
                  title="Loan Total"
                  value={formatCurrency(farm.amount)}
                />
              </Box>

              <Divider />
              <Box sx={{ paddingY: 2 }}>
                <KeyValueText
                  title="Loan Interest"
                  value={formatCurrency(interest)}
                />
              </Box>
              <Divider />
              <Box sx={{ paddingY: 2 }}>
                <KeyValueText
                  title="Total"
                  value={formatCurrency(Number(farm.amount) + Number(interest))}
                />
              </Box>

              {farm?.status === "open" && (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={isPending || isPendingAccept}
                    variant="contained"
                    size={"large"}
                    sx={{ mt: 3, mb: 2, mx: 2 }}
                    onClick={() => acceptLoan({ loanId: farm?.id })}
                  >
                    Approve Loan
                  </Button>

                  <Button
                    type="submit"
                    fullWidth
                    color="error"
                    variant="contained"
                    size={"large"}
                    disabled={isPending || isPendingAccept}
                    onClick={() => rejectLoan({ loanId: farm?.id })}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Reject Loan
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default LoanDetailsPage;
