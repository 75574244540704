import { Box, Tab, Tabs, Typography } from "@mui/material";
import * as React from "react";
import CustomTabPanel from "../../components/layout/customTabPanel/customTabPanel";
import IndividualFarm from "./components/individualFarm";
import RecentFarmAdded from "./components/recentFarmAdded";
import { useQueryUser } from "../../api/queries";
import Loading from "../../components/layout/loading/Loading";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FarmTrackPage() {
  const [value, setValue] = React.useState(0);

  const { isLoading } = useQueryUser();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Loading isLoading={isLoading} />
      <Box sx={{ borderBottom: 1, borderColor: "divider", pt: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{
              textTransform: "none",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "23.5px",
            }}
            label="Small Holder Farm"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              textTransform: "none",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "23.5px",
            }}
            label="Individual  Farms"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <RecentFarmAdded />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <IndividualFarm />
      </CustomTabPanel>
    </Box>
  );
}
