import { Box, Tab, Tabs } from "@mui/material";
import * as React from "react";
import { useQueryProduct, useQueryStats } from "../../api/queries";
import CustomTabPanel from "../../components/layout/customTabPanel/customTabPanel";
import Loading from "../../components/layout/loading/Loading";
import Inventory from "./components/inventory";
import Orders from "./components/oders";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StorePage() {
  const [value, setValue] = React.useState(0);
  const { isLoading } = useQueryStats();
  const { isLoading: isLoadingProduct } = useQueryProduct();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Loading isLoading={isLoading || isLoadingProduct} />
      <Box sx={{ borderBottom: 1, borderColor: "divider", pt: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{
              textTransform: "none",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "23.5px",
            }}
            label="Inventory"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              textTransform: "none",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "23.5px",
            }}
            label="Orders"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Inventory />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Orders />
      </CustomTabPanel>
    </Box>
  );
}
